export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "My Profile",
    route: "my-profile",
    icon: "UserIcon",
  },
  {
    title: "Engagements",
    route: "engagements",
    icon: "GridIcon",
  },
]
